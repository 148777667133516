import html2canvas from 'html2canvas';

import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  Icon,
  OnboardingModal,
  Paragraph,
} from '@hl-portals/ui';

import { LO_PORTAL_EVENTS } from '@hl-portals/constants';

import {
  formatCurrency,
  getEquityUnlockRange,
  getFullAddressFromForm,
  normalizeCurrency,
} from '@hl-portals/helpers';

import {
  useLoanOfficerPortalEvent,
  useModal,
  useScreenSize,
} from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { MIN_HOME_VALUE } from '../../../metadata/constants';
import { EquityCalculationLoading } from '../../equity-calculation-loader';
import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '../../layout';
import { EquityBoostBanner } from './EquityBoostBanner';
import { EquityBoostInfo } from './EquityBoostInfo';

type AssumptionProps = {
  label: string;
  value: string;
  highlight?: string;
};

const Assumption = ({ label, value, highlight }: AssumptionProps) => (
  <Box flexDirection="column" gap="8px">
    <Box justifyContent="space-between">
      <Paragraph>{label}</Paragraph>
      <Paragraph textAlign="right">
        {value}
        {highlight && '*'}
      </Paragraph>
    </Box>
    {highlight && (
      <Alert variant="warning" noIcon>
        {highlight}
      </Alert>
    )}
  </Box>
);

export const EquityCalculation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const { watch, setValue } = useFormContext();
  const {
    isFetching,
    isMutating,
    isCalculateEquityFetching,
    isPropertyEligibilityChecksLoading,
    onFetchTitleFlex,
  } = useLeadSubmissionContext();
  const { isMobile } = useScreenSize();

  const {
    equity_unlock_result,
    progress_banner_dismissed,
    loan_officer_email,
    agent_email,
    liens_total,
    property_address,
    property_city,
    property_state,
    property_postal_code,
    property_unit,
    estimated_home_value,
  } = watch();

  const { low_end_range, high_end_range } = equity_unlock_result || {};
  const range = getEquityUnlockRange(low_end_range, high_end_range);

  const hasMinHomeValue =
    normalizeCurrency(estimated_home_value) < MIN_HOME_VALUE;

  const { openModal } = useModal();
  const { logLoanOfficerPortalEvent } = useLoanOfficerPortalEvent();

  const downloadImage = async () => {
    setDownloading(true);

    const elementId = 'equity-unlock-downloadable';
    const element = document.getElementById(elementId);

    html2canvas(element, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById(elementId).style.display = 'block';
      },
    }).then((canvas) => {
      const link = canvas.toDataURL('image/png', 1.0);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = link;
      a.download = 'estimated_equity_unlock_range.png';
      a.click();

      setDownloading(false);
    });
  };

  const handleClick = () => {
    onFetchTitleFlex();
  };

  const isCalculatingEquity = useMemo(() => {
    return (
      isCalculateEquityFetching &&
      isPropertyEligibilityChecksLoading &&
      isFetching + isMutating > 0
    );
  }, [
    isFetching,
    isMutating,
    isCalculateEquityFetching,
    isPropertyEligibilityChecksLoading,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2800);

    if (!isCalculatingEquity) {
      return;
    }

    return () => clearTimeout(timer);
  }, [isCalculatingEquity]);

  if (isLoading) {
    return <EquityCalculationLoading />;
  }

  return (
    <>
      <Content>
        <Container
          title="Estimated equity available"
          subtitle="Estimated equity available from the departing residence."
        >
          {!progress_banner_dismissed && (
            <Alert
              icon="checkCircle"
              iconSize={16}
              variant="success"
              mb="32px"
              onDismiss={() => setValue('progress_banner_dismissed', true)}
            >
              Your progress is saved. Continue your submission now, or resume
              later using the link we emailed you at{' '}
              {loan_officer_email || agent_email}.
            </Alert>
          )}

          <Box id="equity-unlock-downloadable" flexDirection="column">
            <Box
              p="3.125rem 2rem 2rem 2rem"
              flexDirection="column"
              gap="0.5rem"
              border="1px solid #D0ECFF"
              borderRadius="0.5rem 0.5rem 0 0"
              bgcolor="#E9F6FF"
            >
              <Paragraph variant="text-xsmall" letterSpacing="1px">
                EQUITY UNLOCK RANGE:
              </Paragraph>

              <Paragraph variant={isMobile ? 'heading-4' : 'heading-2'}>
                {range}
              </Paragraph>

              {range === '$0' ? (
                <Paragraph variant="text-small" color="#72757D">
                  Our initial review suggests that we may not be able to unlock
                  equity from the home. However, your client may still be
                  eligible for other program benefits.
                </Paragraph>
              ) : (
                <Paragraph variant="text-small" color="#72757D">
                  Equity available for your next home purchase, already
                  accounting for selling and program costs.
                </Paragraph>
              )}
            </Box>
            {property_state !== 'TX' && (
              <EquityBoostBanner
                onClick={() => {
                  logLoanOfficerPortalEvent({
                    event_action:
                      LO_PORTAL_EVENTS.CLICKED_EQUITY_UNLOCK_LEARN_MORE_BANNER,
                    event_payload: {
                      loan_officer_email,
                      agent_email,
                      equity_unlock_result,
                      estimated_home_value,
                      liens_total,
                      property_address,
                      property_city,
                      property_state,
                      property_postal_code,
                    },
                  });
                  openModal(
                    <OnboardingModal onboardingDetails={<EquityBoostInfo />} />
                  );
                }}
              />
            )}
            <Box
              mb="1rem"
              p="3.5rem 1.5rem 2rem 1.5rem"
              flexDirection="column"
              gap="1rem"
              border="1px solid #EEF0F6"
              borderRadius="0 0 0.5rem 0.5rem"
            >
              <Paragraph variant="text-small" fontWeight="600">
                Assumptions:
              </Paragraph>
              <Assumption
                label="Departing Residence"
                value={getFullAddressFromForm({
                  property_address,
                  property_city,
                  property_state,
                  property_postal_code,
                  property_unit,
                })}
              />
              <Assumption
                label="Lien Balances"
                value={formatCurrency({ value: liens_total })}
              />
              <Assumption
                label="Home Value"
                value={formatCurrency({ value: estimated_home_value })}
                highlight={
                  hasMinHomeValue &&
                  '*If the final sale price is under $375,000, the program fee will be $9,000.'
                }
              />
            </Box>
          </Box>

          <Box
            mb={{ xs: '24px', md: '40px' }}
            flexDirection="column"
            gap="16px"
          >
            <Paragraph variant="text-xsmall" color="#8E929C">
              This equity unlock estimate is based on the numbers provided and
              our initial review. It is not a commitment to lend or a formal
              approval. Final figures will be given after further review and
              additional information. Minimums may apply.
            </Paragraph>
            <Button
              onClick={downloadImage}
              iconRight={<Icon type="downloadLine" />}
              color="tertiary"
              variant="outlined"
              size="large"
              isLoading={downloading}
              disabled={downloading}
              width="100%"
            >
              Download Range
            </Button>
          </Box>
        </Container>
      </Content>

      <Footer>
        <BackButton />
        <NextButton onClick={() => handleClick()} />
      </Footer>
    </>
  );
};
