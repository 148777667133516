import { Box, Button, Logo, Paragraph } from '@hl-portals/ui';

export const ReloadError = () => (
  <Box width="100%" height="100vh" flexDirection="column" bgcolor="#F8F8FB">
    <Box
      p="24px"
      justifyContent="center"
      alignItems="center"
      borderBottom="1px solid #DBDFE6"
      bgcolor="white"
    >
      <Logo variant="horizontal" />
    </Box>

    <Box height="100%" p="16px" justifyContent="center" alignItems="center">
      <Box flexDirection="column" gap="32px" alignItems="center">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z"
            fill="#FFE28D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50 28C50 40.1503 40.1503 50 28 50C15.8497 50 6 40.1503 6 28C6 15.8497 15.8497 6 28 6C40.1503 6 50 15.8497 50 28Z"
            fill="#F1B707"
          />
          <path
            d="M24 36.0815C24 33.9174 25.7909 32.163 28 32.163C30.2091 32.163 32 33.9174 32 36.0815C32 38.2456 30.2091 40 28 40C25.7909 40 24 38.2456 24 36.0815Z"
            fill="white"
          />
          <path
            d="M24.8474 29.6631L24.2023 18.0779C24.1697 17.4924 24.6456 17 25.2442 17H30.7557C31.3543 17 31.8303 17.4924 31.7977 18.0779L31.1526 29.6631C31.1224 30.2052 30.6649 30.6296 30.1107 30.6296H25.8893C25.3351 30.6296 24.8776 30.2052 24.8474 29.6631Z"
            fill="white"
          />
        </svg>

        <Paragraph textAlign="center" mb="20px">
          We were unable to save your progress. Please, try again.
        </Paragraph>
        <Button size="large" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </Box>
    </Box>
  </Box>
);
