import styled from 'styled-components';

import { Box, Paragraph } from '@hl-portals/ui';

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  background: linear-gradient(90deg, #4ba3e3 0%, #45c9a1 100%);
  border-radius: 2px;
  color: white;
  cursor: pointer;
  filter: brightness(1);
  transition: filter 0.2s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 0.5rem;
  z-index: 2;
  ${({ theme }) => theme.breakpoints.up('xs')} {
    width: 104%;
    left: -0.55rem;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 104%;
    left: -0.75rem;
  }

  &:hover {
    filter: brightness(1.05);
  }
`;

interface BoostBannerProps {
  onClick?: () => void;
}

export const EquityBoostBanner = ({ onClick }: BoostBannerProps) => (
  <StyledBox onClick={onClick}>
    <Paragraph
      variant="text-xsmall"
      fontSize={{ xs: '12px', md: '14px' }}
      color="white"
    >
      Unlock more equity with Equity Boost
    </Paragraph>
    <Paragraph
      variant="text-xsmall"
      fontSize={{ xs: '12px', md: '14px' }}
      color="white"
    >
      Learn more
    </Paragraph>
  </StyledBox>
);
