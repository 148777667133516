import { Box, Icon, Paragraph } from '@hl-portals/ui';

import { StepInstructions } from '@equity/modules/portal/components/step-instructions';
import {
  BulletItem,
  Title,
} from '@equity/modules/portal/components/transaction-cards/tasks/modals/equity-boost-modal/steps/intro';

import { STEPS } from './constants';

export const EquityBoostInfo = () => {
  return (
    <Box flex="1" flexDirection="column" gap="1.5rem">
      <Box flexDirection="column">
        <Box mb="0.5rem" alignItems="center" gap="0.5rem">
          <Title>EQUITY BOOST</Title>
          <Icon type="growthIcon" />
        </Box>

        <Paragraph mb="1rem" variant="heading-2">
          Help your client reach their down payment goals
        </Paragraph>
        <Paragraph>
          Increase the equity amount by leveraging additional assets - without
          liquidating
        </Paragraph>
      </Box>

      <Box flexDirection="column" gap="1rem">
        <Paragraph fontWeight="600" fontSize="1rem">
          How does it work?
        </Paragraph>
        <StepInstructions steps={STEPS} />
      </Box>

      <Box flexDirection="column" gap="1rem">
        <Paragraph color="#273653" variant="text" fontWeight="700">
          Requirements
        </Paragraph>
        <BulletItem>
          Accepted Assets: 401(k), IRA, Investments, Brokerage, Gift Funds
        </BulletItem>
        <BulletItem>620+ credit score</BulletItem>
      </Box>
    </Box>
  );
};
