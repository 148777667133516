import { useFlags } from 'flagsmith/react';

import { PropsWithChildren, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  Button,
  Icon,
  Inputs,
  Paragraph,
  TextArea,
  useMultiStepContext,
} from '@hl-portals/ui';

import {
  formatCurrency,
  getEquityUnlockRange,
  getFullAddressFromForm,
} from '@hl-portals/helpers';

import { useModal, useScreenSize } from '@hl-portals/hooks';

import {
  BackButton,
  Container,
  Content,
  Footer,
  NextButton,
} from '@equity/modules/lead-submission/components/layout';
import { useLeadSubmissionContext } from '@equity/modules/lead-submission/contexts/lead-submission';
import {
  HOME_OWNED,
  Lien,
  STEPS,
  Step,
} from '@equity/modules/lead-submission/metadata/constants';

type SectionProps = PropsWithChildren<{
  title: string;
  step: Step;
  isEditable?: boolean;
}>;

const Section = ({ title, children, step, isEditable }: SectionProps) => {
  const { goToStep } = useMultiStepContext();

  return (
    <Box flexDirection="column">
      <Box justifyContent="space-between">
        <Paragraph fontWeight="bold" mb="24px">
          {title}
        </Paragraph>
        {isEditable && (
          <Paragraph
            variant="text-small"
            color="#1192E5"
            onClick={() => goToStep(step)}
            cursor="pointer"
          >
            Edit
          </Paragraph>
        )}
      </Box>
      <Box flexDirection="column" gap="16px">
        {children}
      </Box>
    </Box>
  );
};

type ItemProps = {
  label: string;
  value?: string;
};

const Item = ({ label, value }: ItemProps) => (
  <Box
    pb="16px"
    justifyContent="space-between"
    gap="16px"
    borderBottom="1px solid #DBDFE6"
  >
    <Paragraph>{label}</Paragraph>
    <Paragraph maxWidth="400px" textAlign="right" fontWeight="600">
      {value || '-'}
    </Paragraph>
  </Box>
);

type NotesModalProps = {
  initialValue: string;
  onSave: (value: string) => void;
};

const NotesModal = ({ initialValue, onSave }: NotesModalProps) => {
  const [value, setValue] = useState(initialValue);

  return (
    <Box height="100%" flexDirection="column">
      <Box
        mb="32px"
        p="16px"
        justifyContent="center"
        fontWeight="600"
        borderBottom="1px solid #D9D9D9"
      >
        <Paragraph>Add note (optional)</Paragraph>
      </Box>
      <Box flex="1" flexDirection="column">
        <Paragraph mb="6px">Add note for HomeLight</Paragraph>
        <TextArea
          value={value}
          onChange={({ target }) => setValue(target.value)}
          placeholder="E.g., “The property has three title holders”"
        />
      </Box>
      <Button size="large" onClick={() => onSave(value)}>
        Save
      </Button>
    </Box>
  );
};

export const ReviewAndSubmit = () => {
  const { onSubmit } = useLeadSubmissionContext();
  const { watch, setValue } = useFormContext();
  const { isMobile } = useScreenSize();
  const { openModal, closeModal } = useModal();

  const {
    current_flow,
    property_address,
    property_city,
    property_state,
    property_postal_code,
    property_unit,
    estimated_home_value,
    liens_total,
    liens = [],
    equity_unlock_result,
    target_equity_unlock,
    primary_client_name,
    primary_client_email,
    primary_client_phone,
    has_additional_client,
    additional_client_name,
    additional_client_email,
    additional_client_phone,
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
    agent_name,
    agent_email,
    agent_phone,
    transaction_coordinator_name,
    transaction_coordinator_email,
    transaction_coordinator_phone,
    loan_officer_name,
    loan_officer_email,
    loan_officer_number,
    notes,
  } = watch();

  const { low_end_range, high_end_range } = equity_unlock_result || {};
  const equityUnlockRange = getEquityUnlockRange(low_end_range, high_end_range);
  const isHomeOwned = liens.some((lien: Lien) => lien.type === HOME_OWNED);

  return (
    <>
      <Content flex="1">
        <Container containerProps={{ width: { xs: '100%', md: '1024px' } }}>
          <Paragraph variant="heading-4" mb="48px">
            Review and Submit
          </Paragraph>

          <Box
            flexDirection={{ xs: 'column', md: 'row' }}
            gap="64px"
            mb={{ xs: '24px', md: '40px' }}
          >
            <Box flex="1" flexDirection="column" gap="24px">
              <Section
                title="Departing Residence Information"
                step={STEPS.DEPARTING_RESIDENCE}
              >
                <Item
                  label="Address"
                  value={getFullAddressFromForm({
                    property_address,
                    property_city,
                    property_state,
                    property_postal_code,
                    property_unit,
                  })}
                />
                <Item
                  label="Estimated Home Value"
                  value={formatCurrency({ value: estimated_home_value })}
                />
                <Item
                  label="Liens Balance"
                  value={
                    isHomeOwned
                      ? '$0, home is owned free and clear'
                      : formatCurrency({ value: liens_total })
                  }
                />
                <Item
                  label="Equity Unlock Estimation"
                  value={equityUnlockRange}
                />
                <Item
                  label="Target Equity Amount"
                  value={target_equity_unlock}
                />
              </Section>

              <Section
                isEditable
                title="Client(s) Information"
                step={STEPS.ADD_CLIENT_INFORMATION}
              >
                <Item label="Title Holder" value={primary_client_name} />
                <Item label="Email Address" value={primary_client_email} />
                <Item label="Phone Number" value={primary_client_phone} />

                {has_additional_client && (
                  <>
                    <Item
                      label="Additional Title Holder"
                      value={additional_client_name}
                    />
                    <Item
                      label="Email Address"
                      value={additional_client_email}
                    />
                  </>
                )}
                <Item
                  label="Co-title Holder"
                  value={additional_client_name || '-'}
                />
                <Item
                  label="Email Address"
                  value={additional_client_email || '-'}
                />
                <Item
                  label="Phone Number"
                  value={additional_client_phone || '-'}
                />
              </Section>

              {current_flow === 'lender' && (
                <>
                  <Section
                    isEditable
                    title="Agent Information"
                    step={STEPS.ADD_AGENT_INFO}
                  >
                    <Item label="Name" value={agent_name || '-'} />
                    <Item label="Email Address" value={agent_email || '-'} />
                    <Item label="Phone Number" value={agent_phone || '-'} />
                  </Section>
                  <Section
                    isEditable
                    title="Assistant/Processor"
                    step={STEPS.ADD_ASSISTANT_INFO}
                  >
                    <Item
                      label="Name"
                      value={loan_officer_assistant_name || '-'}
                    />
                    <Item
                      label="Email Address"
                      value={loan_officer_assistant_email || '-'}
                    />
                    <Item
                      label="Phone Number"
                      value={loan_officer_assistant_phone || '-'}
                    />
                  </Section>
                </>
              )}

              {current_flow === 'agent' && (
                <>
                  <Section
                    isEditable
                    title="Finance information"
                    step={STEPS.NEW_HOME_TARGET_EQUITY_AMT}
                  >
                    <Item label="Loan Officer" value={loan_officer_name} />
                    <Item label="Email Address" value={loan_officer_email} />
                    <Item label="Phone Number" value={loan_officer_number} />
                  </Section>
                  <Section
                    isEditable
                    title="Transaction Coordinator"
                    step={STEPS.ADD_TRANSACTION_COORDINATOR_INFO}
                  >
                    <Item
                      label="Name"
                      value={transaction_coordinator_name || '-'}
                    />
                    <Item
                      label="Email Address"
                      value={transaction_coordinator_email || '-'}
                    />
                    <Item
                      label="Phone Number"
                      value={transaction_coordinator_phone || '-'}
                    />
                  </Section>
                </>
              )}
            </Box>

            <Box
              display={{ xs: 'none', sm: 'flex' }}
              width={{ xs: '100%', md: '368px' }}
            >
              <Inputs.TextArea
                name="notes"
                label="Notes for HomeLight"
                placeholder="E.g., “The property has three title holders”"
                optional
              />
            </Box>
          </Box>
        </Container>
      </Content>

      <Footer>
        {isMobile ? (
          <Box flex="1" flexDirection="column" gap="16px">
            <Box
              p="20px 16px"
              flex="1"
              gap="16px"
              borderRadius="12px"
              bgcolor="#F8F8FB"
              cursor="pointer"
              onClick={() =>
                openModal(
                  <NotesModal
                    initialValue={notes || ''}
                    onSave={(v) => {
                      setValue('notes', v);
                      closeModal();
                    }}
                  />
                )
              }
            >
              <Icon type="edit" />
              <Paragraph flex="1" fontWeight="bold">
                Add note{' '}
                <Paragraph as="span" fontWeight="normal" color="#8E929C">
                  (optional)
                </Paragraph>
              </Paragraph>
              <Icon type="angleRight" fill="#8E929C" />
            </Box>
            <NextButton onClick={onSubmit} />
          </Box>
        ) : (
          <>
            <BackButton />
            <NextButton onClick={onSubmit} />
          </>
        )}
      </Footer>
    </>
  );
};
