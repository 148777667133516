import { FormData } from '../../metadata/forms';

export function getLocalStoragePayload(
  partner: string,
  formData: FormData
): Partial<FormData> {
  const {
    current_flow,
    lender_company_name,
    loan_officer_name,
    loan_officer_email,
    loan_officer_number,
    agent_name,
    agent_email,
    agent_phone,
    sales_consultant_name,
    sales_consultant_email,
    sales_consultant_phone,
    external_escrow_officer_name,
    external_escrow_officer_email,
    division,
  } = formData;

  switch (partner) {
    case 'lennar':
      if (current_flow === 'lender') {
        return {
          lender_company_name,
          loan_officer_name,
          loan_officer_email,
          loan_officer_number,
          division,
        };
      }
      return {
        sales_consultant_name,
        sales_consultant_email,
        sales_consultant_phone,
        division,
      };

    case 'newventureescrow':
      if (current_flow === 'lender') {
        return {
          lender_company_name,
          loan_officer_name,
          loan_officer_email,
          loan_officer_number,
          external_escrow_officer_name,
          external_escrow_officer_email,
        };
      }
      return {
        agent_name,
        agent_email,
        agent_phone,
        external_escrow_officer_name,
        external_escrow_officer_email,
      };

    default:
      if (current_flow === 'lender') {
        return {
          lender_company_name,
          loan_officer_name,
          loan_officer_email,
          loan_officer_number,
        };
      }
      return {
        agent_name,
        agent_email,
        agent_phone,
      };
  }
}
