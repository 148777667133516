import { CalculateEquityLenderArgs } from 'packages/hooks/src/useCalculateEquity/calculate-lender-equity';

import { Step } from '../../metadata/constants';
import { FormData } from '../../metadata/forms';
import { UpdateSnapshotPayload } from '../../metadata/types';
import { getSourceForm } from '../get-source-form';

export function getLiensPayload(liens?: FormData['secondary_loans']) {
  if (!liens) return [];

  const liensArr = liens.map((lien) => ({
    type: lien.type,
    balance: `${lien.balance}`,
  }));

  return liensArr;
}

export function getCalculateEquityPayload(
  formData: FormData
): CalculateEquityLenderArgs {
  const {
    current_flow,
    partner_slug,
    secondary_loans,
    loan_officer_name,
    loan_officer_email,
    loan_officer_number,
    lender_company_name,
    property_uuid,
    property_state,
    estimated_home_value,
    snapshot_uuid,
    snapshot_id,
    agent_name,
    agent_email,
    agent_phone,
    sales_consultant_name,
    sales_consultant_email,
    sales_consultant_phone,
    is_home_owned,
  } = formData;

  const sourceForm = getSourceForm(current_flow, partner_slug);
  const liensPayload = is_home_owned ? [] : getLiensPayload(secondary_loans);
  const liensStr = JSON.stringify(liensPayload);

  return {
    lenderName: loan_officer_name,
    lenderEmail: loan_officer_email,
    lenderPhone: loan_officer_number,
    lenderCompany: lender_company_name,
    uuid: property_uuid,
    state: property_state,
    value: estimated_home_value,
    snapshotUuid: snapshot_uuid,
    snapshotId: snapshot_id,
    partnerSlug: partner_slug,
    agentName: agent_name,
    agentEmail: agent_email,
    agentPhone: agent_phone,
    salesConsultantName: sales_consultant_name,
    salesConsultantEmail: sales_consultant_email,
    salesConsultantPhone: sales_consultant_phone,
    sourceForm,
    source: 'web',
    liens: liensStr,
  };
}

export function getSubmitPayload(formData: FormData) {
  const {
    current_flow,
    full_address,
    property_uuid,
    property_state,
    property_unit,
    target_equity_unlock,
    lender_company_name,
    loan_officer_name,
    loan_officer_number,
    loan_officer_email,
    secondary_loans,
    primary_residence,
    no_age_restricted_community,
    no_va_fha_requirement,
    credit_score,
    primary_client_name,
    primary_client_email,
    primary_client_phone,
    agent_email,
    agent_name,
    agent_phone,
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
    transaction_coordinator_name,
    transaction_coordinator_email,
    transaction_coordinator_phone,
    agent_valuation,
    under_contract,
    additional_client_name,
    additional_client_email,
    additional_client_phone,
    notes,
    partner_slug,
    sales_consultant_email,
    sales_consultant_name,
    sales_consultant_phone,
    snapshot_id,
    division,
    automated_bbys_eligibility_check,
    snapshot_uuid,
    estimated_home_value,
    external_escrow_officer_name,
    external_escrow_officer_email,
    is_home_owned,
    new_property_close_of_escrow_date,
    new_home_purchase_timeline,
    finance_type,
    is_mortgage_va_selected,
    is_mortgage_fha_selected,
    departing_residence_held_in_trust,
    pre_submission_file_keys,
  } = formData;

  const sourceForm = getSourceForm(current_flow, partner_slug);

  if (current_flow === 'agent') {
    return {
      uuid: snapshot_uuid,
      source: 'web',
      source_form: sourceForm,
      source_page_type: 'equity_app',
      full_address: full_address,
      estimated_home_value: estimated_home_value,
      pre_submission_file_keys,
    };
  }

  const secondaryLoansPayload = is_home_owned
    ? []
    : getLiensPayload(secondary_loans);

  return {
    source: 'web',
    source_page_type: 'equity_app',
    property_unit: property_unit,
    source_form: sourceForm,
    property_uuid: property_uuid,
    property_state: property_state,
    target_unlock_amount: target_equity_unlock?.replace(/\D/g, ''),
    lender_company: lender_company_name,
    lender_name: loan_officer_name,
    lender_phone: loan_officer_number,
    lender_email: loan_officer_email,
    primary_residence: primary_residence || true,
    no_age_restricted_community: no_age_restricted_community,
    no_va_fha_requirement: no_va_fha_requirement,
    finance_type,
    is_mortgage_va_selected,
    is_mortgage_fha_selected,
    new_home_purchase_timeline,
    loan_officer_assistant_name,
    loan_officer_assistant_email,
    loan_officer_assistant_phone,
    transaction_coordinator_name,
    transaction_coordinator_email,
    transaction_coordinator_phone,
    departing_residence_held_in_trust,
    credit_score: credit_score,
    client_name: primary_client_name,
    client_email: primary_client_email,
    client_phone: primary_client_phone,
    agent_email: agent_email,
    agent_name: agent_name,
    agent_phone: agent_phone,
    agent_valuation: agent_valuation,
    is_client_under_contract_on_new_property: under_contract === 'yes',
    additional_client_name: additional_client_name,
    additional_client_email: additional_client_email,
    additional_client_phone: additional_client_phone,
    pre_submission_file_keys,
    notes: notes,
    partner_slug: partner_slug,
    sales_consultant_email: sales_consultant_email,
    sales_consultant_name: sales_consultant_name,
    sales_consultant_phone: sales_consultant_phone,
    lender_lead_snapshot_id: snapshot_id,
    division: division,
    automated_bbys_check_success: automated_bbys_eligibility_check,
    uuid: snapshot_uuid,
    estimated_home_value: estimated_home_value,
    external_escrow_officer_name: external_escrow_officer_name,
    external_escrow_officer_email: external_escrow_officer_email,
    secondary_loans: secondaryLoansPayload,
    new_property_close_of_escrow_date: new_property_close_of_escrow_date,
  };
}

export function getUpdateSnapshotPayload(
  formData: FormData,
  currentStep: Step
): UpdateSnapshotPayload {
  if (currentStep) {
    return {
      ...formData,
      submitted_step: currentStep,
    };
  }
  return formData;
}

export function getPropertyEligibilityCheckPayload(formData: FormData) {
  const { is_home_owned, secondary_loans } = formData;

  const liensPayload = is_home_owned ? [] : getLiensPayload(secondary_loans);
  const liensStr = JSON.stringify(liensPayload);

  return {
    lenderName: formData.loan_officer_name,
    lenderEmail: formData.loan_officer_email,
    lenderPhone: formData.loan_officer_number,
    lenderCompany: formData.lender_company_name,
    uuid: formData.property_uuid,
    state: formData.property_state,
    value: '' + formData.estimated_home_value,
    flow: formData.flow,
    agentName: formData.agent_name,
    agentEmail: formData.agent_email,
    agentPhone: formData.agent_phone,
    salesConsultantName: formData.sales_consultant_name,
    salesConsultantEmail: formData.sales_consultant_email,
    salesConsultantPhone: formData.sales_consultant_phone,
    liens: liensStr,
  };
}
