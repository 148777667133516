export const STEPS: { key: string; label: string }[] = [
  {
    key: 'step-1',
    label:
      "Submit your client's application and get approved for up to 75% combined-loan-to-value (CLTV) using just the departing residence as collateral",
  },
  {
    key: 'step-2',
    label:
      'If your client needs additional equity, provide proof of assets to increase the equity—up to 85% combined loan-to-value (CLTV)',
  },
  {
    key: 'step-3',
    label: 'We wire funds to the new home escrow—no need to liquidate assets',
  },
];
