import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useRouter } from 'next/router';

import {
  Button,
  ButtonProps,
  Icon,
  useMultiStepContext,
  useSnapshotContext,
} from '@hl-portals/ui';

import { useLocalStorage } from '@hl-portals/hooks';

import { CardRadio } from '../../../../../shared/components/card-radio';
import { UCQuestionnaireFormData } from '../../../metadata/forms';
import { QuestionnaireRouterQuery } from '../../../metadata/types';

type BtnProps = Omit<ButtonProps, 'children'>;

export const BackButton = (props: BtnProps) => {
  const { goBack } = useMultiStepContext();

  return (
    <Button
      type="button"
      display={{ xs: 'none', md: 'flex' }}
      color="tertiary"
      size="large"
      onClick={goBack}
      {...props}
    >
      <Icon type="angleLeft" />
      Back
    </Button>
  );
};

type NextButtonProps = {
  logEvent?: () => void;
  callback?: () => void;
  isAllowedToSkip?: boolean;
} & BtnProps;

export const NextButton = ({
  isAllowedToSkip,
  logEvent,
  callback,
  ...props
}: NextButtonProps) => {
  const router = useRouter();
  const { uuid } = router.query as QuestionnaireRouterQuery;

  const { update } = useSnapshotContext();
  const { goNext, currentStep } = useMultiStepContext();

  const { watch } = useFormContext<UCQuestionnaireFormData>();
  const formData = watch();
  const payload = { ...formData, submitted_step: currentStep };

  const [_, set] = useLocalStorage(formData.leadId);

  const handleClick = () => {
    update(payload, {
      onSuccess: () => {
        if (logEvent) logEvent();
        if (isAllowedToSkip) callback();
        set(uuid);
        goNext();
      },
    });
  };

  return (
    <Button
      type="button"
      size="large"
      flex={{ xs: '1', md: 'unset' }}
      onClick={handleClick}
      {...props}
    >
      Next
    </Button>
  );
};

export const SubmitButton = (props: BtnProps) => (
  <Button type="button" size="large" flex={{ xs: '1', md: 'unset' }} {...props}>
    Submit
  </Button>
);

export const SaveAndExitButton = () => {
  const router = useRouter();
  const { uuid } = router.query as QuestionnaireRouterQuery;

  const { update } = useSnapshotContext();
  const { currentStep } = useMultiStepContext();

  const { watch } = useFormContext<UCQuestionnaireFormData>();
  const formData = watch();
  const payload = { ...formData, submitted_step: currentStep };

  const [, set] = useLocalStorage(formData.leadId);

  return (
    <Button
      size="xsmall"
      flex={{ xs: '1', md: 'unset' }}
      onClick={() => {
        update(payload, {
          onSuccess: () => {
            set(uuid);
            router.push(`/portal/transactions/${formData.leadId}`);
            toast('Progress saved', {
              type: 'success',
              position: 'top-center',
            });
          },
        });
      }}
      color="save-and-exit"
      mt="10px"
      mb="10px"
    >
      Save & Exit
    </Button>
  );
};

export { CardRadio };
